import React, { Component } from 'react';
import MessageList from '../../components/messageList/MessageList'

class MessageArea extends Component {

  render() {
    return (
      <div>
        <MessageList />
      </div>
    );
  }
}

export default MessageArea;